/**
 * Create URL query from object.
 * Replace false value with ""
 * @param {*} obj
 * @returns
 */
export const buildURLQuery = function (obj) {
  return Object.entries(obj)
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&")
  .replaceAll("undefined", "")
  .replaceAll("?", "")
  .replaceAll("false", "");
}

/**
 * Convert search query to object
 */
export const queryParser = function (queryStr) {
  //console.log('queryStr', queryStr);
  let query = queryStr.substring(1)
  let vars = query.split("&")
  let queryObj = {}
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    queryObj[pair[0]] = pair[1]
  }
  return queryObj
}



export const scrollToTop = () => {
  if (typeof window != "undefined") {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
}